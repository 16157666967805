import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "./style.css";


function Agenda() {
  const [datas, setDatas] = useState([]);
  const [none] = useState("none");
  const [flex] = useState("flex");

  useEffect(() => {

    // var pass=prompt("Digite sua idade:");
    
    // if (pass === 'leo3018'){
        firebase
        .firestore()
        .collection("Agenda")
        .orderBy("data")
        .onSnapshot((query) => {
          const list = [];
          query.forEach((element) => {
            list.push({ ...element.data(), id: element.id });
          });
          setDatas(list);
        });
      // }
  }, []);
  return (
    <div className="agenda_wrapper">
      <div className="agenda_container">
        <div className="title">Agenda</div>
        <div className="container_datas">
          {datas.map((item, index) => {
            return (
              <div
                key={index}
                className="data_box"
                style={item.antigo ? { display: none } : { display: flex }}
              >
                <div className="day">
                  {item.data
                    .toDate()
                    .toLocaleDateString("pt-BR")
                    .substring(0, 5)}{" "}
                  .{" "}
                  <span>
                    {item.data
                      .toDate()
                      .toLocaleTimeString("pt-BR")
                      .substring(0, 5)}
                  </span>
                </div>
                <div className="nome_evento">{item.nome}</div>
                <div className="endereco">{item.endereco}</div>
                <div className="paricular">
                  {item.privado ? "evento particular" : "evento publico"}
                </div>
                <a className="mapa" href={item.mapa}>
                  rota para o evento
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Agenda;
