import React from "react";
import { Switch, Route } from "react-router-dom";
import Links from "../Components/Links";
import Agenda from "../Pages/Agenda";
import Home from "../Pages/Home";
import Inicio from "../Pages/Inicio";

function Routes() {
  return (
      <Switch>
        <Route path="/agenda">
          <Agenda />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/site">
          <Inicio />
        </Route>
        <Route  path="/info">
          <Links />
        </Route>
      </Switch>
  );
}

export default Routes;
