import React, { useEffect, useState } from "react";
import "./style.css";
import ReactAudioPlayer from "react-audio-player";
import firebase from "firebase/app";
import "firebase/firestore";
import { secondsToMinutes } from "../../Config/secondsToMinutes";

let ref = null;
function Player() {
  const [current, setCurrent] = useState(0);
  const [duration, setDuration] = useState(1);
  const [toggle, settoggle] = useState("fa-play");

  const [data, setData] = useState([]);
  useEffect(() => {
    var docRef = firebase
      .firestore()
      .collection("Musicas")
      .doc("DGgJho5sUXIsrthEr7Be");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          setData(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    ref.audioEl.current.onloadeddata = () => {
      setDuration(ref.audioEl.current.duration);
      setCurrent(ref.audioEl.current.currentTime);
    };

    ref.audioEl.current.ontimeupdate = () => {
      setCurrent(ref.audioEl.current.currentTime);
      // ref.audioEl.current.currentTime === 0
      //   ? settoggle(play)
      //   : settoggle(pause);
    };

    ref.audioEl.current.onplaying = () => {
      settoggle("fa-pause");
    };
    ref.audioEl.current.onpause = () => {
      settoggle("fa-play");
    };
  }, []);

  console.log(data.audio);

  function togglePlay() {
    ref.audioEl.current.paused
      ? ref.audioEl.current.play()
      : ref.audioEl.current.pause();
  }
  return (
    <div className="player_wrapper">
      <ReactAudioPlayer
        src={data.audio}
        ref={(el) => {
          ref = el;
        }}
        preload="metadata"
      />
      <div className="description_music_wrapper">
        <div className="cover_wrapper">
          <div>
            <img src={data.capa} alt="cover" />
          </div>
        </div>
        <div className="description">
          <div>{data.nome}</div>
          <div>{data.artista}</div>
        </div>
      </div>
      <div className="controls_wrapper">
        <div className="time">{secondsToMinutes(current)}</div>
        {/* <div className="more">
          <div>-10</div>
        </div> */}
        <div className="buttonPlayer">
          <i class="fas fa-fast-backward"></i>
        </div>
        <div className="play" onClick={() => togglePlay()}>
          <i class={`fas ${toggle}`}></i>
        </div>
        <div className="buttonPlayer">
          <i class="fas fa-fast-forward"></i>
        </div>
{/* 
        <div className="more">
          <div>+10</div>
        </div> */}
        <div className="time">{secondsToMinutes(duration)}</div>
      </div>
      {/* 
      <div className="seekBar_wrapper">

        <div className="seekbar"></div>

      </div> */}

      <div className="platform_container">
        <div className="nav_item">
          <a href={data.deezer} target="_blank" rel="noreferrer">
            + deezer
          </a>
        </div>
        <div className="nav_item">
          <a href={data.spotify} target="_blank" rel="noreferrer">
            + spotify
          </a>
        </div>
        <div className="nav_item">
          <a href={data.apple} target="_blank" rel="noreferrer">
            + apple music
          </a>
        </div>
        <div className="nav_item">
          <a href={data.tidal} target="_blank" rel="noreferrer">
            + tidal
          </a>
        </div>
      </div>
    </div>
  );
}

export default Player;
