import React from "react";

import "./style.css";
import fotoleo from "../../Assets/img/leo.png";
function Resume() {
  return (
    <div className="resume_wrapper">
      <div className="resume_container">
        <div className="left">
          <div className="text_container">
            <div className="category">release</div>
            <div className="text">
              A cantor, multi instrumentista e compositor com a mais de 10 anos
              de carreira está conquistando o seu espaço no mundo da música Com
              suas composições e produções, a sua paixão pela música fica
              evidente quando escutamos a sua atuação ao vivo e nas suas
              gravações e regravações. 
              <br />
              <br />
              de Belo Horizonte para o mundo!
            </div>
          </div>
        </div>
        <div className="right">
          <img src={fotoleo} alt="leo" />
        </div>
      </div>
    </div>
  );
}

export default Resume;
