import React, { useEffect } from "react";
import "./style.css";
import logo from "../../Assets/img/lcsv.png";
import $ from "jquery";
import AnchorLink from "react-anchor-link-smooth-scroll";
function Menu() {
  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("active");
        } else {
          $(".menu_wrapper").removeClass("active");
        }
      });
    });
  }, []);

  useEffect(() => {
    $(".drag_container").click(() => {
      $(".menu_full").animate({ width: "toggle" });
      $(".menu_full").css({ display: "flex" });
    });
    $(".close").click(() => {
      $(".menu_full").animate({ width: "toggle" });
    });
    $(".nav_item").click(() => {
      $(".menu_full").animate({ width: "toggle" });
    });
  }, []);

  return (
    <>
      <div className="menu_full">
        <div className="nav_wrapper">
          <AnchorLink href="#inicio">
            <div className="nav_item">inicio</div>
          </AnchorLink>
          <AnchorLink href="#video">
            <div className="nav_item">videos</div>
          </AnchorLink>
          <AnchorLink href="#footer">
            <div className="nav_item">contrate</div>
          </AnchorLink>
          <AnchorLink href="#footer">
            <div className="nav_item">download</div>
          </AnchorLink>
        </div>
        <div className="social_full">
          <div className="category">siga nas redes sociais</div>
          <div className="social_full_center_icons">
            <div className="nav_item_social">
              <a
                href="https://www.instagram.com/oleocostta/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
            <div className="nav_item_social">
              <a
                href="https://www.youtube.com/channel/UC22pLmSSYM-IzZ9f06TtouA"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div className="nav_item_social">
              <a
                href="https://www.tiktok.com/@oleocostta"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-tiktok"></i>
              </a>
            </div>
            <div className="nav_item_social">
              <a
                href="https://www.facebook.com/leocoficial/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="close">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div className="menu_wrapper">
        <div className="logo_wrapper">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav_wrapper">
          <AnchorLink href="#inicio">
            <div className="nav_item">inicio</div>
          </AnchorLink>
          <AnchorLink href="#video">
            <div className="nav_item">videos</div>
          </AnchorLink>
          <AnchorLink href="#footer">
            <div className="nav_item">contrate</div>
          </AnchorLink>
          <AnchorLink href="#footer">
            <div className="nav_item">download</div>
          </AnchorLink>
          <div className="divisa"></div>
          <div className="nav_item_social">
            <a
              href="https://www.instagram.com/oleocostta/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.youtube.com/channel/UC22pLmSSYM-IzZ9f06TtouA"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.tiktok.com/@oleocostta"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-tiktok"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.facebook.com/leocoficial/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          </div>
        </div>
        <div className="drag_container">
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  );
}

export default Menu;
