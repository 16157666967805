import "./App.css";
// import Menu from "./Components/Menu";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
// import Footer from "./Components/Footer";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
