import React from "react";

import "./style.css";
import logo from "../../Assets/img/lcsv.png";
function Links() {
  return (
    <div className="links_wrapper">
      <div className="link_container">
        <img src={logo} alt="logo" />

        <a href="https://api.whatsapp.com/send?phone=5531992487124&text=Ol%C3%A1%20Tudo%20Bem%3F" target="_blank" rel="noreferrer">
          <button>contrate</button>
        </a>
        <a href="https://www.youtube.com/channel/UC22pLmSSYM-IzZ9f06TtouA" target="_blank" rel="noreferrer">
          <button>Youtube</button>
        </a>

        <a href="https://open.spotify.com/artist/0QiTmZbVWU3pVnuv6mRneU" target="_blank" rel="noreferrer">
          <button>spotify</button>
        </a>

        <a href="https://www.deezer.com/br/artist/86480012" target="_blank" rel="noreferrer">
          <button>deezer</button>
        </a>
        <a href="https://www.leocostta.com.br/" target="_blank" rel="noreferrer">
          <button>site oficial</button>
        </a>
      </div>
    </div>
  );
}

export default Links;
