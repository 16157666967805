import React from "react";
import sample from "../../Assets/video/apenas.mp4";
import Player from "../Player";
import "./style.css";
function Banner() {
  return (
    <div className="banner_wrapper" id="inicio">
      <div className="banner_container">
        {/* <div className="left">
          <div className="button_video">
            <i class="fas fa-play"></i>
          </div>
          <div className="category">assista agora</div>
          <div className="title">APENAS DISTRAÇÃO</div>
          <div className="sub_text">
            música de trabalho do artista leo costta já disponível em todas as plataformas
            digitais <span>Assista agora no youtube!</span>
          </div>
          <a href="https://youtu.be/nvKCUaZlr5c" target="_blank" rel="noreferrer"><button>assistir agora</button></a>
        </div>

        <div className="right"></div> */}
      </div>

      <div className="overlay"></div>
      <video className="videoTag" autoPlay loop muted>
        <source src={sample} type="video/mp4" />
      </video>

      <Player />
    </div>
  );
}

export default Banner;
