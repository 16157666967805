import React from "react";

import "./style.css";
import logo from "../../Assets/img/lcsv.png";
import king from "../../Assets/img/king.png";
function Footer() {
  return (
    <div className="footer_wrapper" id="footer">
      <div className="footer_container">
        <div className="box_footer">
          <img src={logo} alt="logo" />
        </div>
        <div className="box_footer">
          <div className="title_category_footer">compositores</div>
          <div className="info_text">enviar material para</div> <br />
          <div className="comp_text_tel">
          (031) 99126-1722 <span> whatsapp</span>
          </div>
        
        </div>
        <div className="box_footer">
          <div className="title_category_footer">contatos</div>
          (031) 99248-7124 <br />
          (031) 99126-1722 <br />
          (031) 97331-0440
        </div>
        <div className="box_footer">
          <div className="title_category_footer">baixar arquivos</div>
          <div className="text_print">
          baixe<span> fotos e logomarca</span> para divulgação em materiais impresso e/ou digitais!
          </div>
          
         <a href="https://drive.google.com/drive/folders/1RIu89qaX92cpdl7fZLP8bD7W3U10SBWg?usp=sharing" target="_blank" rel="noreferrer"><button>baixar</button></a>
        </div>
      </div>
      <div className="sunfooter">

        <div className="king_logo_wrapper">
        <img src={king} alt="king" />
        </div>
        @2021 LEO COSTTA | TODOS OS DIREITOS RESERVADOS

        <div className="social_footer">
        <div className="nav_item_social">
            <a
              href="https://www.instagram.com/oleocostta/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.youtube.com/channel/UC22pLmSSYM-IzZ9f06TtouA"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.tiktok.com/@oleocostta"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-tiktok"></i>
            </a>
          </div>
          <div className="nav_item_social">
            <a
              href="https://www.facebook.com/leocoficial/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          </div>
        </div>
        </div>
    </div>
  );
}

export default Footer;
