import React from "react";
import "./style.css";
import logo from "../../Assets/img/LOGOLC.png";
function Inicio() {
  return (
    <div className="inicio_wrapper">
      <div className="inicio_container">
        <div className="left">
            <div>
            <img src={logo} alt="logo" />
            </div>
          <button>Entrar no site</button>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
}

export default Inicio;
