import React from 'react';
import Banner from '../../Components/Banner';
import Resume from '../../Components/Resume';
import Video from '../../Components/Video';
import Menu from '../../Components/Menu';
import Footer from '../../Components/Footer';


function Home() {
  return(
      <>
      <Menu/>
    <Banner/>
    <Video/>
    <Resume/>
    <Footer/>
    </>
  );
}

export default Home;