import React from "react";

import "./style.css";
import spotify from "../../Assets/img/spotify.svg";
import deezer from "../../Assets/img/deezer-logo.svg";
import apple from "../../Assets/img/apple-music.svg";
import amazon from "../../Assets/img/amazon.svg";
import youtube from "../../Assets/img/youtube.svg";
import tidal from "../../Assets/img/tidal.svg";

function Video() {
  return (
    <div className="video_wrapper" id="video">
      <div className="video_container">
        <div className="category">assista agora</div>
        <iframe
          src="https://www.youtube.com/embed/nvKCUaZlr5c"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div className="category_streamings">ouça agora</div>
        <div className="social_container">
          <div className="social_middle">
            <a
              href="https://open.spotify.com/artist/0QiTmZbVWU3pVnuv6mRneU"
              target="_blank"
              rel="noreferrer"
            >
              <img src={spotify} alt="social" />
            </a>
            <a
              href="https://www.deezer.com/br/artist/86480012"
              target="_blank"
              rel="noreferrer"
            >
              <img src={deezer} alt="social" />
            </a>
            <a
              href="https://music.apple.com/br/artist/leo-costta/1499034834"
              target="_blank"
              rel="noreferrer"
            >
              <img src={apple} alt="social" />
            </a>
            <a
              href="https://music.amazon.com/artists/B08514F5TW/leo-costta"
              target="_blank"
              rel="noreferrer"
            >
              <img src={amazon} alt="social" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC22pLmSSYM-IzZ9f06TtouA"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="social" />
            </a>
            <a
              href="https://tidal.com/browse/artist/18439299"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tidal} alt="social" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
